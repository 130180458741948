import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取基线的列表
export function get_baseline_list(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines`,

    method: "get",
  });
}

// 创建基线
export function create_baseline(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines`,

    method: "post",
    data,
  });
}

// 删除基线
export function delete_baseline(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}`,

    method: "delete",
  });
}

// 复制基线
export function copy_baseline(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/replica`,

    method: "post",
    data,
  });
}

// 恢复删除节点
export function recover_node(projectId, baselineId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/nodes/${nodeKey}/recover`,

    method: "put",
  });
}

// 基线名称修改
export function change_baseline_name(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/name`,

    method: "put",
    data,
  });
}

// 基础信息修改
export function change_baseinfo(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}`,

    method: "put",
    data,
  });
}

// 获取基线的详情
export function get_data(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}`,

    method: "get",
  });
}

// 获取基线的changeLog
// tag 已修改
export function get_changelog(projectId, baselineId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/changeLogs`,

    method: "get",
    params: {
      nodeKey,
    },
  });
}

// 结束基线
export function finish_baseline(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/complated`,

    method: "put",
  });
}

// 修改基线的锁定
export function change_lock(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/locked`,

    method: "put",
  });
}

// 获取基线中的节点
export function get_nodes(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/nodes`,

    method: "get",
  });
}

// 批量添加节点到基线中
export function add_nodes(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/nodes`,

    method: "post",
    data,
  });
}

// 批量添加文件到基线中
export function add_files(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/files`,

    method: "post",
    data,
  });
}

// 从基线中删除节点
export function delete_nodes(projectId, baselineId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/nodes/${nodeKey}`,

    method: "DELETE",
  });
}

// 开始基线
export function start_baseline(projectId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/started`,

    method: "put",
  });
}

// 根据fileId获取file的基础信息
// tag 无影响
export function get_file(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/fileId/${fileId}`,

    method: "get",
  });
}

// 基线存档功能
// 查看存档
export function get_archive(projectId, baselineId, pageNumber, pageSize) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/archiveList?pageNumber=${pageNumber}&pageSize=${pageSize}`,

    method: "get",
  });
}

// 存档 
export function archive_baselines(projectId, baselineId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/${baselineId}/archive`,

    method: "post",
    data
  });
}