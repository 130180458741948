import { getFile } from "@/network/home/index.js";
import { download_drawio } from "@/network/fileOperation/index.js";
import htmlDocx from 'html-docx-js/dist/html-docx';
import saveAs from 'file-saver';
import { mapGetters } from "vuex";

const globleMixin = {
    data() {
        return {
            word_result: [],
            word_timeOut: null,
            word_name: "",
            word_html_str: [],
            imgPosition: 0
        }
    },
    computed: {
        ...mapGetters({
            mixalluserlist: "user_list",
            specialUsers: "special_users"
        }),
        specialMixalluserlist() {
            return Object.assign({}, this.mixalluserlist, this.specialUsers)
        },
    },
    watch: {},
    methods: {
        get_pid() {
            return this.$route.params.projectId || "";
        },
        tree_data(original_data) {
            original_data.sort(function (m, n) {
                if (m.index < n.index) return -1
                else if (m.index > n.index) return 1
                else return 0
            });
            const arr = [];

            function search_children(data, root) {
                const items = [];
                for (let i = 0; i < data.length; i++) {
                    if (root == data[i].parentid) {
                        data[i].children = search_children(data, data[i].id);
                        const temp_item = Object.assign(data[i], { label: data.topic });
                        items.push(temp_item);
                    }
                }
                return items;
            }

            original_data.forEach((item) => {
                if (item.isroot) {
                    arr.push(Object.assign(item, { label: item.topic }));
                    arr[0].children = search_children(original_data, "root");
                }
            });
            return arr;
        },
        async svgBase64ToPngBase64(imageBase64, flag) {
            const img = new Image(); // 创建图片容器
            img.src = imageBase64 + (flag ? '?s=' + Math.random().toString() : ''); //imageBase64 为svg+xml的Base64 文件流
            img.setAttribute("crossOrigin", 'Anonymous')
            let promise = new Promise((reslove) => {
                img.onload = function () {
                    // 图片创建后再执行，转Base64过程
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const context = canvas.getContext('2d');
                    context.drawImage(img, 0, 0, img.width, img.height);
                    let pngBase64 = canvas.toDataURL('image/png');
                    reslove(pngBase64);
                }
            })
            return await promise;
        },
        build_html(arr, str, promiseList) {
            for (let arri = 0; arri < arr.length; arri++) {
                const link = `${location.protocol}//${location.hostname}/${this.get_pid()}/nodes/key/${arr[arri].key}`
                str.push(`<H${arr[arri].layer - 1}>${arr[arri].topic}<a href="${link}">${arr[arri].key}</a></H${arr[arri].layer - 1}>`)
                // 获取描述中的图片
                const desdiv = document.createElement('div');
                // console.log("arr[arri].description:", arr[arri].description)
                desdiv.innerHTML = arr[arri].description;
                // console.log("desdiv.innerHTML:", desdiv.innerHTML)
                const imglist = desdiv.querySelectorAll('img');
                // console.log("imglist:", imglist)
                imglist.forEach((img) => {
                    const span = document.createElement('span');
                    span.innerText = `drawidmap${this.imgPosition++}`
                    // console.log("span.innerText:", span.innerText)
                    const replaceimg = img.parentNode.replaceChild(span, img);
                    // console.log("replaceimg:", replaceimg)
                    promiseList.push(this.svgBase64ToPngBase64(replaceimg.src, true))
                })
                str.push(desdiv.innerHTML)
                // console.log("after desdiv.innerHTML:", desdiv.innerHTML)
                if (arr[arri].architectureDiagrams) {
                    for (let i = 0; i < arr[arri].architectureDiagrams.length; i++) {
                        str.push(`drawidmap${this.imgPosition++}`)
                        promiseList.push(download_drawio(arr[arri].architectureDiagrams[i].data))
                    }
                }
                if (arr[arri].content) {
                    for (let contenti = 0; contenti < arr[arri].content.length; contenti++) {
                        if (arr[arri].content[contenti].fieldType === "DRAWIO" && arr[arri].content[contenti].value) {
                            const value = JSON.parse(arr[arri].content[contenti].value)
                            for (let valuei = 0; valuei < value.length; valuei++) {
                                str.push(`drawidmap${this.imgPosition++}`)
                                promiseList.push(download_drawio(value[valuei].data))
                            }
                        }
                    }
                }
                if (arr[arri].children) {
                    this.build_html(arr[arri].children, str, promiseList)
                }
            }
        },
        exportWord(row) {
            const self = this;
            this.word_name = row.file_name;
            let onloadtime = 0
            this.$message({
                type: "warning",
                message: "正在导出中",
                duration: "5000",
            });
            getFile(this.get_pid(), row.file_key).then((fileInfo) => {
                const arr = this.tree_data(fileInfo.data);
                this.word_html_str = []
                // 创建Worker
                const worker = new Worker('/js/worker/exportWord.js');
                // 发送消息
                worker.postMessage({ arr: arr, pid: this.get_pid() });
                worker.addEventListener('message', function (e) {
                    const { promises, str } = e.data;
                    self.word_html_str = str;
                    let promiseList = []
                    promises.forEach(promise => {
                        if (promise.type === 'img') {
                            promiseList.push(self.svgBase64ToPngBase64(promise.data, true))
                        } else if (promise.type === 'drawio') {
                            promiseList.push(download_drawio(promise.data))
                        }
                    })
                    Promise.all(promiseList).then((res) => {
                        res.forEach((item, index) => {
                            if (typeof item === "string") {
                                self.word_html_str.forEach((desstr, desindex) => {
                                    if (desstr.indexOf(`drawidmap${index}`) !== -1) {
                                        self.word_html_str[desindex] = desstr.replace(`drawidmap${index}`, `<img src="${item}"></img>`)
                                    }
                                })
                                onloadtime++;
                                if (onloadtime === res.length) {
                                    const page = '<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + self.word_html_str.join('') + '</body></html>'
                                    const converted = htmlDocx.asBlob(page);
                                    // 用 FielSaver.js里的保存方法 进行输出
                                    saveAs(converted, fileInfo.meta.name + '.docx');
                                    self.$message({
                                        type: "success",
                                        message: self.$t('globleMixin.ExportCompleted'),
                                        duration: "5000",
                                    });
                                }
                            } else {
                                const reader = new FileReader();
                                reader.readAsText(item);
                                reader.onload = function (e) {
                                    self.svgBase64ToPngBase64(e.target.result, false).then((pngurl) => {
                                        self.word_html_str.splice(self.word_html_str.indexOf(`drawidmap${index}`), 1, `<img src="${pngurl}"></img>`)
                                        onloadtime++;
                                        if (onloadtime === res.length) {
                                            const page = '<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + self.word_html_str.join('') + '</body></html>'
                                            const converted = htmlDocx.asBlob(page);
                                            // 用 FielSaver.js里的保存方法 进行输出
                                            saveAs(converted, fileInfo.meta.name + '.docx');
                                            self.$message({
                                                type: "success",
                                                message: self.$t('globleMixin.ExportCompleted'),
                                                duration: "5000",
                                            });
                                        }
                                    });
                                };
                            }
                        })

                    }, (error) => {
                        self.$message({
                            type: "error",
                            message: self.$t('globleMixin.ExportFailed'),
                            duration: "5000",
                        });
                    })
                    if (!promiseList.length) {
                        const page = '<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + self.word_html_str.join('') + '</body></html>'
                        const converted = htmlDocx.asBlob(page);
                        // 用 FielSaver.js里的保存方法 进行输出
                        saveAs(converted, fileInfo.meta.name + '.docx');
                        self.$message({
                            type: "success",
                            message: self.$t('globleMixin.ExportCompleted'),
                            duration: "5000",
                        });
                    }
                });
            })
        },
        /**
         *通过用户id匹配用户名称
         *@param {string, string} 用户id 无匹配用户返回字符串
         *@return {string} 返回用户昵称(若无匹配用户直接返回第二参数)
         */
        matchUserNickname(id, str) {
            return this.specialMixalluserlist[id] ? this.specialMixalluserlist[id].nickname : str;
        },
        /**
         *通过用户id匹配用户所有信息
         *@param {string} 用户id
         *@return {Object} 返回用户所有信息
         */
        matchUserInfo(id) {
            return this.specialMixalluserlist[id] ? this.specialMixalluserlist[id] : null;
        }
    }
}
export default globleMixin
