import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取未分配的状态列表
export function unassigned_statusList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/unassignedStatusList`,
    method: "get",
  });
}
// 获取面板详情
export function scrumBoard_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/detail`,
    method: "get",
  });
}
// 为scrum面板增加一列
export function add_unassigned_statusList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/unassignedStatusList`,
    method: "post",
    data: params.data,
  });
}
// 删除Scrum面板列
export function delete_unassigned_statusList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/columns/${params.columnId}`,
    method: "delete",
  });
}
// scrum面板列排序
export function column_order(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/columnOrder`,
    method: "put",
    data: params.data,
  });
}
// 基础信息的设计
export function settings(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/settings`,
    method: "put",
    data: params.data,
  });
}
// 分配面板列的状态
export function column_statusList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/columnStatusList`,
    method: "put",
    data: params.data,
  });
}
// 按类型获取sprints列表
export function get_sprints(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/sprints`,
    method: "get",
  });
}
// 获取sprint设置信息
export function get_sprints_info(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}`,
    method: "get",
  });
}
// 创建一个Sprint
export function add_sprints(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/scrumBoard/sprints`,
    method: "post",
    data: params.data,
  });
}
// 编辑一个Sprint
export function edit_sprint(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}`,
    method: "put",
    data: params.data,
  });
}
// sprint状态切换接口
export function change_sprint_status(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}/status`,
    method: "put",
    data: params.data,
  });
}
// 获取指定sprint下面的所有节点
// tag 无影响
export function get_sprint_nodes(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}/nodes`,
    method: "get",
  });
}
// 添加节点到指定sprint
export function add_sprint_nodes(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}/nodes`,
    method: "post",
    data: params.data,
  });
}
// 删除节点到指定sprint
export function delete_sprint_nodes({ projectId, sprintId, nodeKey }) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/sprints/${sprintId}/nodes/${nodeKey}`,
    method: "DELETE",
  });
}
// 批量在sprints间移动节点
export function node_move(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprintNodeRelation/nodeMove`,
    method: "put",
    data: params.data,
  });
}

// 获取kanban面板的设置信息
export function kanban_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/setting`,
    method: "get",
  });
}
// 获取kanban面板的设置信息
export function kanban_unassigned_statusList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/unassignedStatusList`,
    method: "get",
  });
}
// 获取kanban面板的设置信息
export function kanban_setting(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/setting`,
    method: "put",
    data: params.data,
  });
}
// 删除kanban面板列
export function kanban_delete_columns(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/columns/${params.columnId}`,
    method: "delete",
  });
}
// 为kanban面板增加一列
export function kanban_add_columns(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/columns`,
    method: "post",
    data: params.data,
  });
}
// 分配kanban面板列的状态
export function kanban_column_statuslist(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/columnStatusList`,
    method: "put",
    data: params.data,
  });
}
// 分配kanban面板列的状态
// tag 无影响
export function kanban_nodes(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/nodes`,
    method: "get",
  });
}
// 新增节点到看板
export function add_kanban_nodes(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/nodes`,
    method: "post",
    data: params.data
  });
}
// 移出节点到看板
export function delete_kanban_nodes(params) {
  let query = ''
  params.nodeKeys.forEach(k => {
    query += `nodeKeys=${k}&`
  })
  const urlParams = query.substring(0, query.length - 1)
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/batchDeleteNodes?${urlParams}`,
    method: "delete",
  });
}
// scrum面板列排序
export function kanbanBoard_column_order(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/columnOrder`,
    method: "put",
    data: params.data,
  });
}
// scrum面板列排序
export function kanbanBoard_column_number(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/columns/${params.columnId}?number=${params.number}`,
    method: "put",
  });
}
// 获取指定sprint下面的已完成的所有节点的统计图表
export function resultStatistics(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprintsResultStatistics?type=${params.query.type}`,
    method: "post",
    data: params.query.ids
  });
}
// 获取指定sprint下面的已完成的所有节点
// tag 无影响
export function completeNodes(params, ids) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprintsCompleteNodes`,
    method: "post",
    data: ids
  });
}

// 统计故事点
export function storyPointStatistics(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}/storyPointStatistics`,
    method: "post",
  });
}
// 敏捷看板节点排序
export function verticalOrder(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/sprints/${params.sprintId}/verticalOrder`,
    method: "put",
    data: params.data
  });
}
// 看板节点排序
export function verticalOrderKANBAN(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/kanbanBoard/verticalOrder`,
    method: "put",
    data: params.data
  });
}
// 查询节点是否已经加入看板
export function kanbanFetch(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/kanban/nodes/fetch`,
    method: "post",
    data: params.data
  });
}
// sprint 汇总
export function getBatchAnalyzeNodeStatus(params) {
  let query = ''
  params.data.sprintIds.forEach(k => {
    query += `sprintIds=${k}&`
  })
  const urlParams = query.substring(0, query.length - 1)
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/sprints/batchAnalyzeNodeStatus?${urlParams}`,
    method: "get",
  });
}