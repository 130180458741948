export const sessionSave = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const sessionRead = (key) => {
  return sessionStorage.getItem(key) || '';
};

export const sessionClear = () => {
  window.sessionStorage.clear();
};

export const sessionRemove = (key) => {
  window.sessionStorage.removeItem(key);
};