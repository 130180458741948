import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取用户所有协作的文件
export function get_user_collaboration_files(email) {
  const data = {
    email: email,
  };
  return request({
    url: `/home/my_collaboration`,
    method: "post",
    data: data,
  });
}

// 获取用户指定协作的文件
export function get_user_collaboration_file(email, mindmapId) {
  const data = {
    email: email,
    mindmapId: mindmapId,
  };
  return request({
    url: `/home/my_collaboration`,
    method: "post",
    data: data,
  });
}

// 获取用户回收站的文件
// tag 已修改
export function get_user_bin(
  projectId,
  pageNumber,
  pageSize,
  desc,
  sortby,
  fuzzyKey
) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/recycleBin/files`,
    method: "post",
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    data: {
      fuzzyKey: fuzzyKey,
      desc: desc,
      queryConditions: [],
      sortby: sortby,
    },

  });
}

// 彻底删除用户回收站中的文件-cc
export function delete_bin_file(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/recycleBin/files/${fileKey}`,
    method: "delete",

  });
}

// 恢复用户回收站中的文件-cc
export function recover_bin_file(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/recycleBin/files/${fileKey}/recovery`,
    method: "put",

  });
}
// 彻底删除用户回收站中的文件(批量)
export function batchDel_bin_file(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/recycleBin/files/batchDel`,
    method: "post",
    data: {
      fileKeys: params.fileKeys,
    },

  });
}
// 恢复用户回收站中的文件(批量)
export function batchRecover_bin_file(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/recycleBin/files/batchRecover`,
    method: "post",
    data: {
      fileKeys: params.fileKeys,
    },

  });
}
// 彻底删除回收站中的文件
export function delete_or_recover_user_bin(email, files, action) {
  const data = {
    email: email,
    files: files,
    action: action,
  };
  return request({
    url: `/home/bin`,
    method: "post",
    data: data,
  });
}

export function cover_file(email, name, fid) {
  const data = {
    email: email,
    name: name,
    fid: fid,
  };
  return request({
    url: `/home/cover`,
    method: "post",
    data: data,
  });
}

export function rename_cover(email, old_name, name, fid) {
  const data = {
    email: email,
    old_name: old_name,
    name: name,
    fid: fid,
  };
  return request({
    url: `/home/rename_cover`,
    method: "post",
    data: data,
  });
}

//------------------新API-------------------------

export function get_jobAuthorities(projectId) {
  //获得项目对应权限
  return request({
    url: `/${serviceConfig['project-service']}/project/job/authority`,
    method: "get",
    params: {
      projectId: projectId,
    },

  });
}

export function add_new_job(projectId, name, jobAuthorities) {
  // 增加新职位
  return request({
    url: `/${serviceConfig['project-service']}/project/job`,
    method: "post",
    data: {
      jobAuthorities: jobAuthorities,
      name: name,
      projectId: projectId,
    },

  });
}

export function edit_job_name(projectId, jobId, name) {
  // 重命名职位
  return request({
    url: `/${serviceConfig['project-service']}/project/job`,
    method: "put",
    data: {
      projectId: projectId,
      jobId: jobId,
      name: name,
    },

  });
}

export function update_jobAuthorities(projectId, jobId, name, jobAuthorities) {
  //修改项目对应权限配置
  return request({
    url: `/${serviceConfig['project-service']}/project/job/authority`,
    method: "put",
    data: {
      projectId: projectId,
      jobId: jobId,
      name: name,
      jobAuthorities: jobAuthorities,
    },

  });
}

export function delete_job(projectId, jobId) {
  // 删除职位
  return request({
    url: `/${serviceConfig['project-service']}/project/job`,
    method: "delete",
    data: {
      projectId: projectId,
      jobId: jobId,
    },

  });
}

export function get_authorities() {
  // 获得权限列表
  return request({
    url: `/${serviceConfig['project-service']}/project/job/authorityEnums`,
    method: "get",

  });
}

export function get_authority_types() {
  // 获得权限类型列表
  return request({
    url: `/${serviceConfig['project-service']}/project/job/authorityTypeEnums`,
    method: "get",

  });
}
export function get_file_List(
  desc,
  pageNumber,
  pageSize,
  projectId,
  sortby,
  condition,
  fuzzyKey
) {
  //获得项目文件列表
  // tag 已修改
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/filter`,
    method: "post",
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    data: {
      fuzzyKey: fuzzyKey,
      queryConditions: condition,
      desc: desc,
      sortby: sortby,
    },

  });
}

export function get_filetype(projectId) {
  //获取项目组下面的所有文件类型
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes`,
    method: "get",
  });
}

export function createNewFile(
  projectId,
  fileName,
  fileTypeId,
  testCase,
  testPlanId
) {
  //创建新文件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files`,
    method: "post",
    data: {
      name: fileName,
      fileTypeId,
      testCase,
      testPlanId,
    },
  });
}

export function getFile(projectId, fileKey) {
  //获取文件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}`,
    method: "get",

  });
}

export function getReleaseElements(projectId, fileKey) {
  //获取lock以及sprintID
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/nodes/releaseElements`,
    method: "get",

  });
}

export function removeFile(projectId, fileKey) {
  //删除文件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}`,
    method: "DELETE",

  });
}

export function refileName(projectId, fileKey, options) {
  //文件重命名
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}`,
    method: "PUT",
    data: options,
  });
}

export function refileCopy(projectId, fileKey) {
  //文件复制
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileCopy/${fileKey}`,
    method: "POST",
  });
}

export function save_condition(
  desc,
  name,
  projectId,
  sortby,
  condition,
  fuzzyKey
) {
  //保存筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/condition`,
    method: "post",
    params: {
      name,
    },
    data: {
      fuzzyKey: fuzzyKey,
      queryConditions: condition,
      desc: desc,
      sortby: sortby,
    },

  });
}

export function batchDel(params) {
  //文件批量删除
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/files/batchDel`,
    method: "post",
    data: {
      fileKeys: params.fileKeys,
    },

  });
}
export function people_get(projectId) {
  //获得项目所有成员
  return request({
    url: `/${serviceConfig['project-service']}/allProjectMember`,
    method: "get",
    params: {
      projectId: projectId,
    },

  });
}
export function jobAuthorities(params) {
  //获取当前用户的职位权限列表
  return request({
    url: `/${serviceConfig['project-service']}/${params.projectId}/projectMember/jobAuthorities`,
    method: "get",

  });
}
export function condition_get(projectId) {
  //获取筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/condition`,
    method: "get",

  });
}

export function condition_delete(projectId, queryConditionId) {
  //删除筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/deleteCondition/${queryConditionId}`,
    method: "delete",

  });
}
export function condition_change(projectId, queryConditionId, params) {
  //更改筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/modifyCondition/${queryConditionId}`,
    method: "put",
    data: params,
  });
}
export function condition_rename(projectId, queryConditionId, rename) {
  //更改筛选条件名字
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/renameCondition/${queryConditionId}?rename=${rename}`,
    method: "put",

  });
}
export function fileOrigin(params) {
  //获取源画布key
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/fileOrigin/${params.fileKey}`,
    method: "get",
  });
}
export function nodeOrigins(params) {
  //获取源画布key
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodeOrigins`,
    data: params.data,
    method: "post",
  });
}
export function getShortcuts(params) {
  //获取左菜单快捷方式
  return request({
    url: `/${serviceConfig['project-service']}/${params.projectId}/shortcuts`,
    method: "get",
  });
}
export function addShortcuts(params) {
  //新增左菜单快捷方式
  return request({
    url: `/${serviceConfig['project-service']}/${params.projectId}/shortcuts`,
    method: "post",
    data: params.data
  });
}

export function deleteShortcuts(params) {
  //获取左菜单快捷方式
  return request({
    url: `/${serviceConfig['project-service']}/${params.projectId}/shortcuts/${params.shortcutId}`,
    method: "delete",
  });
}

// 创建文生产品
export function createNewFileMgpt(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/mgpt`,
    method: "post",
    data: params.data
  });
}

// mgpt付费询问
export function mgptPayFeatureStatus(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/mgpt/payFeatureStatus`,
    method: 'get'
  })
}

export function get_model_view(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/files/${fileKey}/architectureDiagram`,
    method: 'get'
  })
}

export function put_model_view(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/files/${fileKey}/architectureDiagram`,
    method: 'put',
    data: data,

  })
}


export function put_model_view_template(data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileArchitectureDiagrams`,
    method: 'post',
    data: data,
  })
}

export function get_all_model_view_template() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileArchitectureDiagrams`,
    method: 'get',
    params: {
    }
  })
}
export function get_fuzz_model_view_template(string) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileArchitectureDiagrams`,
    method: 'get',
    params: {
      diagramName: string,
    }
  })
}
export function get_id_model_view_template(fileArchitectureDiagramsId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileArchitectureDiagrams/${fileArchitectureDiagramsId}`,
    method: 'get'
  })
}
export function del_id_model_view_template(fileArchitectureDiagramsId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileArchitectureDiagrams/${fileArchitectureDiagramsId}`,
    method: 'delete'
  })
}
