import {
  get_project_members
} from "@/network/projectMember/index.js";
const project = {
  state: {
    avatar: "",
    avatarUrl: "",
    createdAt: "",
    createdBy: "",
    decription: "",
    id: "",
    user: "",
    project_type: "" || sessionStorage.getItem("project_type"),
    manageList: [],
    memberCount: 0,
    projectKey: "",
    projectName: "",
    tenantId: -1,
    updatedAt: "",
    projectRole: "" || sessionStorage.getItem("projectRole"),
    projectThirdApps: [],
    repository_list: {},
    gitee_repository_list: {},
    github_repository_list: {},
  },

  mutations: {
    SET_REPOSITORY_LIST: (state, data) => {
      state.repository_list = data
    },
    SET_GITEE_REPOSITORY_LIST: (state, data) => {
      state.gitee_repository_list = data
    },
    SET_GITHUB_REPOSITORY_LIST: (state, data) => {
      state.github_repository_list = data
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_AVATARURL: (state, avatarUrl) => {
      state.avatarUrl = avatarUrl;
    },
    SET_CREATEDAT: (state, createdAt) => {
      state.createdAt = createdAt;
    },
    SET_DESCRIPTION: (state, description) => {
      state.description = description;
    },
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_PROJECT_TYPE: (state, project_type) => {
      sessionStorage.setItem("project_type", project_type);
      state.project_type = project_type;
    },
    SET_MANAGERLIST: (state, managerList) => {
      state.managerList = managerList;
    },
    SET_MEMBERCOUNT: (state, memberCount) => {
      state.memberCount = memberCount;
    },
    SET_PROJECTKEY: (state, projectKey) => {
      state.projectKey = projectKey;
    },
    SET_PROJECTNAME: (state, projectName) => {
      state.projectName = projectName;
    },
    SET_TENANTID: (state, tenantId) => {
      state.tenantId = tenantId;
    },
    SET_UPDATEDAT: (state, updatedAt) => {
      state.updatedAt = updatedAt;
    },
    SET_PROJECTROLE: (state, projectRole) => {
      sessionStorage.setItem("projectRole", projectRole);
      state.projectRole = projectRole;
    },
    SET_THIRD_APPS: (state, projectThirdApps) => {
      state.projectThirdApps = [...projectThirdApps];
    },
  },

  actions: {
    projectRole({
      commit
    }, p_id) {
      get_project_members(p_id).then((res) => {
        const current_user_id = JSON.parse(localStorage.getItem("store")).user
          .userAccountId;
        res.forEach((user) => {
          if (user.accountId === current_user_id) {
            commit("SET_PROJECTROLE", user.projectRole);
          }
        });
      });
    },
  },
};

export default project;