const setting = {
 state: {
  page_layer:3,
  page_typeid:"",
  page_name:"",
  isCollapse: false,
 },

 mutations: {
  SET_PAGE_LAYER: (state, page_layer) => {
   state.page_layer = page_layer
  },
  SET_PAGE_TYPEID: (state, page_typeid) => {
   state.page_typeid = page_typeid
  },
  SET_PAGE_NAME: (state, page_name) => {
   state.page_name = page_name
  },
  SET_COLLAPSE: (state, value)=>{
   state.isCollapse = value
  }
 },

 actions: {

 }
}

export default setting
