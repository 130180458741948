import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'


export function get_review() {
  return request({
    url: `${serviceConfig['file-manage-service']}//workstation/ReviewProcess/receiving`,
    method: "get",
  })
}

export function get_all_file(number, size) {
  return request({
    url: `${serviceConfig['file-manage-service']}/workstation/myFiles?pageNumber=${number}&pageSize=${size}`,
    method: "get",
  })
}

export function get_all_node(number, size) {
  return request({
    url: `${serviceConfig['file-manage-service']}/workstation/myNodes?pageNumber=${number}&pageSize=${size}`,
    method: "get",
  })
}

export function get_collections(type, number, size) {
  return request({
    url: `${serviceConfig['mindmap-service-v2']}/collections/${type}?page=${number}&page_size=${size}`,
    method: "get",
  });
}

export function get_icon() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileTypes`,
    method: "get",
  });
}

export function changeRequests() {
  return request({
    url: `${serviceConfig['file-manage-service']}/workstation/changeRequests`,
    method: "get",
  });
}

//获取 ‘指定我为负责人’ 的列表
export function get_assignee_list(page, pageSize) {
  return request({
    url: `${serviceConfig['mindmap-service']}/workbench/nodes/assignee?page=${page}&page_size=${pageSize}`,
    method: "get",
  })
}

//获取@我的列表
export function get_atMe_list(page, pageSize) {
  return request({
    url: `${serviceConfig['mindmap-service']}/workbench/nodes/atMe?page=${page}&page_size=${pageSize}`,
    method: "get",
  })
}

export function get_projectNums(query) {
  return request({
    url: `${serviceConfig['file-manage-service']}/tenant/fileAndNodeNums?page=${query.page}&size=${query.size}&sortBy=${query.sortType}&desc=${query.desc}`,
    method: "get",
  })
}

export function get_nodeAssigneeByTenantId(projectId) {
  return request({
    url: `${serviceConfig['file-manage-service']}/tenant/nodeAssignee`,
    data: projectId,
    method: "post",
  })
}

export function get_manHourByTenantId(projectId) {
  return request({
    url: `${serviceConfig['file-manage-service']}/tenant/manHour`,
    data: projectId,
    method: "post",
  })
}

export function get_assigneeStatusByTenantId(projectId) {
  return request({
    url: `${serviceConfig['file-manage-service']}/tenant/assigneeStatus`,
    data: projectId,
    method: "post",
  })
}