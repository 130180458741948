import { get_grouplist } from "@/network/addGroup/index.js";

const store_tenant = {
  state: {
    grouplist:[],
    isLoading:false,
  },
  mutations: {
    GET_GROUP(state,value){
      state.grouplist=value
    },
    CHANGE_LOAD(state,value){
      state.isLoading=value
    }
  },
  actions: {
  async getGroup({commit},data){
     await get_grouplist(data.page,data.size).then((res)=>{
        commit('GET_GROUP',res)
        commit('CHANGE_LOAD',false)
      })
    }
  }
}

export default store_tenant
