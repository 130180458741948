import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 密码登录方法
export function login_with_password(email, password) {
  const data = {
    email,
    password
  }
  return request({
    url: `/${serviceConfig['account-service']}/signIn`,
    method: 'post',
    data: data
  })
}

// 验证码登录
export function login_with_verify_code(email, verifyCode) {
  const data = {
    email,
    verifyCode
  }
  return request({
    url: `/${serviceConfig['account-service']}/signIn/verifyCode`,
    method: 'post',
    data: data,
  })
}

// 忘记密码
export function forget_password(email, newPassword, verifyCode) {
  const data = {
    email,
    password: newPassword,
    verifyCode
  }
  return request({
    url: `/${serviceConfig['account-service']}/login/resetting`,
    method: 'put',
    data: data
  })
}

// 邮箱注册
export function register_with_email(data) {
  return request({
    url: `/${serviceConfig['account-service']}/signUp`,
    method: 'post',
    data: data,
  })
}

// 请求验证码
export function request_verify_code(email, type) {
  return request({
    url: `/${serviceConfig['account-service']}/verifyCode?email=${email}`,
    method: 'post',
  })
}
// 接受邀请调取接口获取邮箱
export function getEmail(userRedisUUID) {
  return request({
    url: `/${serviceConfig['account-service']}/acceptInvitation/getEmail/${userRedisUUID}`,
    method: 'get'
  })
}
// 新用户确认接受邀请，如果有其它租户存在相同邮箱用户，其它用户自动离职
export function acceptInvitation(params) {
  return request({
    url: `/${serviceConfig['account-service']}/acceptInvitation/${params.userRedisUUID}`,
    headers: {
      Accept: 'application/json, text/plain,  */*',
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: params.data
  })
}
//用户点击邮箱中接受邀请连接后，检查租户上限，是否存在其它租户中，以及删除原租户的影响
export function check(userRedisUUID) {
  return request({
    url: `/${serviceConfig['account-service']}/acceptInvitation/check/${userRedisUUID}`,
    method: 'get'
  })
}
//V5图形验证获取token
export function getV5Token(userRedisUUID) {
  return request({
    url: `/${serviceConfig['account-service']}/captcha/token`,
    method: 'get'
  })
}
//V5图形验证获取host
export function getV5Host(userRedisUUID) {
  return request({
    url: `/${serviceConfig['account-service']}/captcha/host`,
    method: 'get'
  })
}
// 请求手机验证码
export function requestPhoneVerifyCode(params) {
  return request({
    url: `/${serviceConfig['account-service']}/phoneVerifyCode?phone=${params.phone}&countryCode=${params.countryCode}`,
    method: 'post',
    data: params.verifyReqDto
  })
}
//  退出登录
// 邮箱注册
export function loginOut() {
  return request({
    url: `/${serviceConfig['account-service']}/loginOut`,
    method: 'delete',
  })
}

// 获取租户手机号信息
export function getPhoneInfo(params) {
  return request({
    url: `/${serviceConfig['account-service']}/tenantInfo`,
    method: 'get',
  })
}

// 提交手机号
export function supplementPhone(params) {
  return request({
    url: `/${serviceConfig['account-service']}/supplementPhone`,
    method: 'post',
    data: params
  })
}

// 推荐其他人注册
export function inviteMark() {
  return request({
    url: `/${serviceConfig['account-service']}/inviteMark`,
    method: 'get',
  })
}