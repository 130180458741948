
import { get_tenant_user_list_nopage, getSpecialUsers, get_tenant_user_group_list_nopage } from "@/network/user/index.js";
import { sessionRead, sessionSave } from '@/utils/session';
import { get_icon } from "@/network/workstation/index.js";

const store_tenant = {
  state: {
    joined_projects: [],
    user: sessionRead('_user') ? JSON.parse(sessionRead('_user')) : {},
    userGroup: sessionRead('_userGroup') ? JSON.parse(sessionRead('_userGroup')) : {},
    specialUsers: sessionRead('_specialUsers') ? JSON.parse(sessionRead('_specialUsers')) : {},
    watermask_switch: false,
    watermask_value: '',
    all_file_type: {},
  },
  mutations: {
    set_joined_projects: (state, data) => {
      state.joined_projects = data;
    },
    setUser(state, obj) {
      state.user = obj;
      sessionSave('_user', JSON.stringify(obj));
    },
    setuserGroup(state, value) {
      state.userGroup = value;
      sessionSave('_userGroup', JSON.stringify(value));
    },
    setAllFileType(state, value) {
      state.all_file_type = value;
      sessionSave('_allFileType', JSON.stringify(value));
    },
    setSpecialUsers(state, obj) {
      state.specialUsers = obj;
      sessionSave('_specialUsers', JSON.stringify(obj));
    },
    SET_WATERMASK(state, obj) {
      state.watermask_switch = obj.waterMaskSwitch;
      state.watermask_value = obj.waterMaskValue;
    }
  },
  actions: {
    setUser({ commit }) {
      get_tenant_user_list_nopage().then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.accountId] = item;
        })
        commit('setUser', obj)
      })
      getSpecialUsers().then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.accountId] = item;
        })
        commit('setSpecialUsers', obj)
      })
    },
    setuserGroup({ commit }) {
      get_tenant_user_group_list_nopage().then((res) => {
        commit('setuserGroup', res)
      })
    },
    setAllFileType({ commit }) {
      let d = {}
      get_icon().then((res) => {
        res.forEach(i => {
          d[i.fileTypeId] = { icon: i.icon, name: i.name }
        })
        commit('setAllFileType', d)
      })
    }
  }
}

export default store_tenant
