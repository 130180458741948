const env = process.env.VUE_APP_BASE_API_BASE_URL;
const configObj = {
  production: {
    authentication_url: "https://ms.ytdevops.com/",
    gitee_clientID: "f98574981041b35b307ba773db31582cfa187f727390d9bc402c3620ac090152",
    github_clientID: "1fe26255a93fb8228bc5",
    oss_url: 'https://automind-oss.ytdevops.com',
    oss_region: 'oss-cn-shanghai',
    oss_bucket: 'ytdevops-automind',
    // 用户头像替换上传地址
    replace_url: 'https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com',
    //临时代码
    allow_gantt: false,
    allow_gantt_tenantId: "586ee243-5501-4712-bf0b-0e36b5ea2ff6",
    jira_url: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=zodDIKqO1IwKJFnzizUn9X8MVnpChum6&scope=read:jira-work%20offline_access&redirect_uri=https://ms.ytdevops.com/authenticationJira&state=123&response_type=code&prompt=consent'
  },
  development: {
    authentication_url: 'http://127.0.0.1/',
    gitee_clientID: "633ef3ae8a16cdd8f5a6e1bf7c4a8c86156b882b683c172f3bee10444a602e1b",
    github_clientID: "b95d9e9bae9eee7ee460",
    oss_url: 'http://automind-oss.ytdevops.com',
    oss_region: 'oss-cn-shanghai',
    oss_bucket: 'ytdevops-automind',
    // 用户头像替换上传地址
    replace_url: 'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com',
    //临时代码
    allow_gantt: true,
    jira_url: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=QvI7mOTxbePeH2bEDwsqBMzO3PcrZvK8&scope=read:jira-work%20offline_access&redirect_uri=http://localhost:1024/authenticationJira&state=123&response_type=code&prompt=consent'
  },
  test: {
    authentication_url: 'https://test-ms.ytdevops.com/',
    gitee_clientID: "633ef3ae8a16cdd8f5a6e1bf7c4a8c86156b882b683c172f3bee10444a602e1b",
    github_clientID: "b95d9e9bae9eee7ee460",
    oss_url: 'https://automind-oss.ytdevops.com',
    oss_region: 'oss-cn-shanghai',
    oss_bucket: 'ytdevops-automind',
    // 用户头像替换上传地址
    replace_url: 'https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com',
    //临时代码
    allow_gantt: true,
    jira_url: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=etlfpqKRsSsJ3T0axwmRnSQRjdJ2xUy3&scope=read:jira-work%20offline_access&redirect_uri=https://test-automind-v2.ytdevops.com/authenticationJira&state=123&response_type=code&prompt=consent'
  },
  intl: {
    authentication_url: "https://ms-intl.ytdevops.com/",
    gitee_clientID: "98d54855207a08c7fd733e79073c0027e8f69308146b09f2578429518ddbc04c",
    github_clientID: "6845b5fe7a14ad74f9a3",
    oss_url: 'https://automind-global-oss.ytdevops.com',
    oss_region: 'oss-ap-southeast-1',
    oss_bucket: 'ytdevops-automind-sg',
    // 用户头像替换上传地址
    replace_url: 'https://automind-global-oss.ytdevops.com',
    //临时代码
    allow_gantt: false,
    allow_gantt_tenantId: "586ee243-5501-4712-bf0b-0e36b5ea2ff6",
    jira_url: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jSN0BsLrixWcz4C58p73HfzdzDA9Ejk1&scope=read:jira-work%20offline_access&redirect_uri=https://ms-intl.ytdevops.com/authenticationJira&state=123&response_type=code&prompt=consent'
  },

};
export default {
  ...configObj[env]
};