/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    let part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof value[key] !== "undefined") {
            let params = propName + "[" + key + "]";
            let subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}

// 处理后端返回的时间2022-03-18T01:46:08.000+00:00 转换为：年-月-日 时:分:秒
export function formatTime(cellValue) {
  let s = new Date(cellValue).toLocaleString();
  return s;
}

// 判断数据类型
export function getDataType(data) {
  const temp = Object.prototype.toString.call(data);
  const type = temp.match(/\b\w+\b/g);
  return (type.length < 2) ? 'Undefined' : type[1];
}

// 判断两个对象是否相等
export function isObjectChanged(source, comparison) {
  const iterable = (data) => ['Object', 'Array'].includes(getDataType(data));
  if (!iterable(source)) {
    throw new Error(`source should be a Object or Array , but got ${getDataType(source)}`);
  }

  if (getDataType(source) !== getDataType(comparison)) {
    return true;
  }

  const sourceKeys = Object.keys(source);

  const comparisonKeys = Object.keys({...source, ...comparison});

  if (sourceKeys.length !== comparisonKeys.length) {
    return true;
  }

  return comparisonKeys.some(key => {
    if (iterable(source[key])) {
      return isObjectChanged(source[key], comparison[key]);
    } else {
      return source[key] !== comparison[key];
    }
  });
}