import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'

// 添加用户组
export function add_group(group_arr) {
    const data = group_arr;
    return request({
        url: `/${serviceConfig['account-service']}/userGroups`,
        method: 'post',
        data: data,
    })
}

// 分页获取用户组
export function get_grouplist(page, size) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups?page=${page}&size=${size}`,
        method: 'get',
    })
}

// 删除用户组
export function delete_user_group(userGroupId) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups/${userGroupId}`,
        method: 'DELETE',
    })
}


//获取单个用户组信息
export function get_single_group(userGroupId) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups/${userGroupId}`,
        method: 'GET',
    })
}

//修改单个用户组信息 
export function modify_single_group(userGroupId, data) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups/${userGroupId}`,
        method: 'put',
        data: data,
    })
}

//模糊搜索用户组
export function vague_search_group(pagination, name) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups/fuzzySearchPage?page=${pagination.page}&size=${pagination.size}&fuzzyName=${name}`,
        method: 'get',
    })
}

//获取用户组和用户列表
export function get_group_user_list(data) {
    return request({
        url: `/${serviceConfig['account-service']}/userGroups/members`,
        method: 'post',
        data: data,
    })
}