import Vue from 'vue'
import { getStyle } from 'element-ui/src/utils/dom';
import store from "@/store";
import i18n from '@/i18n/index'

/**
 * ak-tooltip-auto-show
 * 当text没有被折叠时，不显示tooltip
 */
Vue.directive('ak-tooltip-auto-show', {
  inserted: function (el, binding, vnode) {
    el.addEventListener('mouseenter', function (e) {
      let defalutSilent = !!Vue.config.silent;
      Vue.config.silent = true;
      vnode.componentInstance.disabled = true;
      const range = document.createRange();
      range.setStart(el, 0);
      range.setEnd(el, el.childNodes.length);
      const rangeWidth = Math.round(range.getBoundingClientRect().width);
      const padding = (parseInt(getStyle(el, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(el, 'paddingRight'), 10) || 0);
      if (rangeWidth + padding + 2 > el.offsetWidth || el.scrollWidth > el.offsetWidth) {
        vnode.componentInstance.disabled = false;
      }
      Vue.config.silent = defalutSilent;
    });
  }
});
function show_operation_tip(e) {
  const x = e.clientX;
  const y = e.clientY;
  const dom = document.querySelector('.limit_operation_tip');
  dom.style.top = y + 10 + 'px';
  dom.style.left = (x - 80) + 'px';
  dom.style.display = 'flex';
  dom.style.width = "auto";
  dom.style.padding = "0 10px";
  dom.style.height = 'auto'
}
function hide_operation_tip(e) {
  const dom = document.querySelector('.limit_operation_tip');
  dom.style.display = 'none';
}
function limits_of_authority_fn(el, binding, vnode) {
  const jobAuthorities = store.getters.jobAuthorities;
  const value = binding.value;
  const flag = jobAuthorities.indexOf(value) === -1;
  const wrap = document.createElement('div');
  wrap.className = 'limit_operation_wrap';
  if (!document.querySelectorAll('.limit_operation_tip').length) {
    const tip = document.createElement('div');
    tip.innerText = i18n.t('tip')
    tip.className = 'limit_operation_tip';
    document.body.appendChild(tip);
  }
  if (flag) {
    el.classList.add("limit_operation");
    el.parentNode.style.cursor = "not-allowed";
    el.parentNode.addEventListener('mouseover', show_operation_tip);
    el.parentNode.addEventListener('mouseout', hide_operation_tip);
  } else {
    el.classList.remove("limit_operation");
    el.parentNode.style.cursor = "pointer";
    el.parentNode.removeEventListener('mouseover', show_operation_tip)
    el.parentNode.removeEventListener('mouseout', hide_operation_tip)
  }
}
// 权限问题 自定义指令
Vue.directive('limits-of-authority', {
  inserted: limits_of_authority_fn,
  componentUpdated: limits_of_authority_fn,
});
