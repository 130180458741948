const notice = {
  state: {
    notice: false,
    all:0
  },
  mutations: {
    SET_NOTICE: (state, notice) => {
      state.notice = notice;
    },
    SET_NOTICE_NUM: (state, num) => {
      state.all = num;
    },
  },
  actions: {}
}

export default notice
