import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'

// 判断名称是否已存在
export function exist_name(email, name) {
  const data = {
    email,
    name
  }
  return request({
    url: `/mindmap/exist_name`,
    method: 'post',
    data: data
  })
}

//重命名（含判断文件名称是否存在）
export function reset_fileName(email, old_name, name) {
  const data = {
    email,
    old_name,
    name
  }
  return request({
    url: `/mindmap/reset_fileName`,
    method: 'post',
    data: data
  })
}

// 获取当前文件协作人员
export function get_collaborators(email, fid) {
  const data = {
    email,
    fid
  }
  return request({
    url: `/mindmap/try_cooperation`,
    method: 'post',
    data: data
  })
}

// 获取当前文件协作人员
export function sent_invitation_to_collaborate(email, coid, fid, type) {
  const data = {
    email,
    coid,
    fid,
    type
  }
  return request({
    url: `/mindmap/cooperation`,
    method: 'post',
    data: data
  })
}

// 新API

export function get_allProjectMember(projectId) {
  //获取项目中所有的成员
  return request({
    url: `/${serviceConfig['project-service']}/allProjectMember?projectId=${projectId}`,
    method: 'get',
  })
}

export function add_node(projectId, node) {
  //新建节点
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/nodes/BatchCreate`,
    method: 'post',
    data: node,
  })
}
export function delete_node(projectId, nodeKey) {
  //删除节点
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}`,
    method: 'delete',
  })
}
export function get_multiLayers_context(params) {
  //获取批量编辑右键菜单内容
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/fileTypes/${params.fileTypeId}/multiLayers/fields?layerNumbers=${params.layerNumbers}`,
    method: 'get',
  })
}
// v模型穿透图详情
export function get_v_model_map_details(params) {
  // projectId,sourceFileTypeId,targetFileTypeId
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/vModel/through/${params.sourceFileTypeId}/${params.targetFileTypeId}?page=${params.page}&page_size=${params.page_size}`,
    method: 'post',
    data: params.data,
    headers: {
      'content-type': 'application/json'
    }
  })
}
export function nodeAutoExtend(params) {
  //AI 自动补全节点
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/openai/nodeAutoExtend`,
    data: params.data,
    method: 'post',
  })
}