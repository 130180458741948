import {sessionRead, sessionSave } from '@/utils/session';
const release = {
  state: {
    //基线
    baselineId: sessionRead('_baselineId') || '',
    baseline_status: "",
    is_edit: false,
  },

  mutations: {
    //基线
    SET_BASELINE_ID: (state, baselineId) => {
      sessionSave('_baselineId', baselineId)
      state.baselineId = baselineId;
    },
    SET_BASELINE_STATUS: (state, val) => {
      state.baseline_status = val;
    },
    SET_BASELINE_EDIT: (state, val) => {
      state.is_edit = val;
    },
  },
};

export default release;
