import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
//将思维导图保存为文档
export function export_mindMap(email, type, file) {
  const data = {
    email,
    type,
    file
  }
  return request({
    url: `/mindmap/export_file`,
    method: 'post',
    data: data,
    async: false,
    responseType: 'blob'

  })
}

export function searchFilesByFuzzyKey(fuzzyKey, limit = 20) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/tenant/fileFuzzyQuery`,
    method: 'get',
    params: {
      fuzzyKey,
      limit
    }
  })
}

export function searchNodesByFuzzyKey(fuzzyKey, limit = 20) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/tenant/nodes/fuzzy`,
    method: 'get',
    params: {
      fuzzyKey,
      limit
    }
  })
}

export function searchKnowledgesByFuzzyKey(fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/tenant/documentFuzzyQuery`,
    method: 'get',
    params: {
      fuzzyKey,
    }
  })
}
export function import_others(data) {

  return request({
    url: `/mindmap/import_file`,
    header: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data,
    async: false,
  })
}

export function import_excel(data) {
  return request({
    url: `/mindmap/judge_excel`,
    header: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data,
    async: false,
  })
}

export function upload_drawio(data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/mediaFile`,
    header: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data,
  })
}


export function download_drawio(url) {
  return request({
    url: url,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;application/octet-stream'
    },
    method: 'get',
  })
}
export function download_knowledge_drawio(url) {
  return request({
    url: url,
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*'
    },
    method: 'get',
  })
}
//下载excel模板

export function download_model_excel() {

  return request({
    url: `/download_model_excel`,
    method: 'post',
    async: false,
    responseType: 'blob'
  })
}


