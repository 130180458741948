import Vue from "vue";

import store from "./store";
import App from "./App.vue";
import "./registerServiceWorker";
import "./plugins/element.js";
import "./plugins/tooltipAuto.js";
import router from "./router";

import jm from "./plugins/jsmind.js";
import "./assets/css/font.css";
import "./assets/css/theme.scss";
import "./assets/css/quill.custom.css";
import "./assets/css/quill-better-table.css";
// 引入iconfont
import './assets/iconfont/iconfont.css';
// import "@/icons";
import echarts from "./utils/echarts.js";
import "lib-flexible-computer";
import vmson from "@/utils/vmson";
import "@/utils/dateformat";
import globleMixin from "@/utils/mixin/globleMixin";
import "./assets/css/limit_operation.css";
import VueTour from "vue-tour";
import { DataZoomComponent } from "echarts/components";
// markDown富文本编辑器
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import directive from '@/utils/elDialogDrag'
import '@/utils/drawer-drag'
import VueMermaidString from 'vue-mermaid-string'
Vue.use(directive)
echarts.use([DataZoomComponent]);

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);
Vue.use(mavonEditor)
Vue.use(VueMermaidString)
import i18n from "./i18n"; //
// import "./assets/icon-font/iconfont.css"
Vue.mixin(globleMixin);
const env = process.env.VUE_APP_BASE_API;
Vue.use(jm);
if (window.jsMind) {
  Vue.prototype.jsMind = window.jsMind;
}
if (env === "/prod-api") {
  Vue.prototype.wsurl = ``;
} else if (env === "http://localhost:8081") {
  Vue.prototype.wsurl = "http://localhost:8081";
} else if (env === "https://ms-intl-api.ytdevops.com") {
  Vue.prototype.wsurl = "https://ms-intl-api.ytdevops.com";
} else {
  Vue.prototype.wsurl = "https://ms-api.ytdevops.com";
}
Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts;

Vue.prototype.$vmson = vmson;

//评审规则下拉列表不能删除
import { Message } from "element-ui";
Vue.directive("defaultSelect", {
  componentUpdated(el, bindings) {
    const [...defaultValues] = bindings.value;
    const [...tags] = el.querySelectorAll(".el-tag");
    tags.forEach((tag) => {
      const textEl = tag.querySelector(".el-select__tags-text");
      defaultValues.forEach((value) => {
        if (value.name === textEl.textContent) {
          tag.querySelector(".el-tag__close").classList.add("none");
        }
      });
    });
  },
});
// Vue.directive('el-drawer-drag-height', {
//   bind(el, binding, vnode, oldVnode) {
//     const drawerEle = el.querySelector('.el-drawer')
//     const dragItem = document.createElement('div')
//     dragItem.style.cssText = 'height: 5px;width: 100%;cursor: s-resize;position: absolute;top: 0;'
//     drawerEle.append(dragItem)

//     const moveCallback = function (e) {
//       // e.preventDefault();
//       // e.stopPropagation();
//       let realHeight = document.body.clientHeight - e.pageY
//       const height30 = document.body.clientHeight * 0.4
//       const height80 = document.body.clientHeight * 1
//       realHeight = realHeight > height80 ? height80 : realHeight < height30 ? height30 : realHeight
//       drawerEle.style.height = realHeight + 'px'
//     }

//     dragItem.addEventListener('mousedown', () => {
//       document.body.style.userSelect = 'none'
//       document.addEventListener('mousemove', moveCallback)
//       document.addEventListener('mouseup', () => {
//         document.body.style.userSelect = 'initial'
//         document.removeEventListener('mousemove', moveCallback)
//         // document.removeEventListener('mouseup')
//       })
//     })
//   }
// })
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
