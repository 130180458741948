import { login_with_password, login_with_verify_code, loginOut } from '@/network/login/index.js'
import { jobAuthorities } from '@/network/home/index.js'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { sessionRemove } from "@/utils/session";
import { MessageBox } from 'element-ui';
import { setLanguage } from "@/network/user/index.js";
import i18n from '@/i18n/index'
import router from '@/router'

const user = {
  state: {
    token: getToken(),
    name: '',
    email: '',
    avatar: '',
    roles: [],
    permissions: [],
    userInfo: {},
    userAccountId: '',
    jobAuthorities: '' || JSON.parse(sessionStorage.getItem('jobAuthorities'))
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
      state.userInfo.nickname = name
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
      state.userInfo.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      state.userInfo.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      state.avatar = userInfo.avatar
      state.name = userInfo.nickname
      state.roles = userInfo.roles
      state.userAccountId = userInfo.accountId
      sessionStorage.setItem('role', JSON.stringify(userInfo.roles))
    },
    SET_JOBAUTHOR: (state, p_id) => {
      jobAuthorities({ projectId: p_id }).then((res) => {
        const jobAuthorities = res.jobAuthorities || []
        state.jobAuthorities = jobAuthorities;
        sessionStorage.setItem('jobAuthorities', JSON.stringify(jobAuthorities))
      })
    },
    //刷新state
    REFRESE_STATE(state, user) {
      if (user.token && user.token !== state.token && user.email !== state.email) {
        setToken(user.token)
        this.commit('SET_EMAIL', user.email)
        this.commit('SET_TOKEN', user.token)
        this.commit('SET_dsUSERINFO', user.userInfo)
        MessageBox.alert(i18n.t('addModules.alert'), {
          confirmButtonText: i18n.t('addModules.btn'),
          showClose: false,
          callback: action => {
            router.push('/home').then(() => {
              sessionRemove('p_id')
              window.location.reload()
            })
          }
        })
      }
    }
  },
  actions: {
    // 密码登录
    Login_password({ commit }, userInfo) {
      const email = userInfo.email.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        login_with_password(email, password).then(res => {
          setToken(res.token)
          commit('SET_EMAIL', email)
          commit('SET_TOKEN', res.token)
          commit('SET_USERINFO', res.userInfo)
          const map = {
            zh: "zh-CN",
            en: "en-US",
          };
          setLanguage({
            language: map[localStorage.getItem('lang')] || 'zh-CN',
          }).then((res) => { });
          resolve(1)
        }).catch(error => {
          removeToken();
          commit('SET_TOKEN', '');
          reject(error)
        })
      })
    },
    // 邮件验证登录
    Login_email({ commit }, userInfo) {
      const email = userInfo.email.trim()
      const vercode = userInfo.vercode_user
      return new Promise((resolve, reject) => {
        login_with_verify_code(email, vercode).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          commit('SET_EMAIL', email)
          commit('SET_USERINFO', res.userInfo)
          const map = {
            zh: "zh-CN",
            en: "en-US",
          };
          setLanguage({
            language: map[localStorage.getItem('lang')] || 'zh-CN',
          }).then((res) => { });
          resolve(1)
        }).catch(error => {
          removeToken();
          commit('SET_TOKEN', '');
          reject(error)
        })
      })
    },
    // 退出登录
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        loginOut().then(res => {
          commit("SET_EMAIL", "");
          commit("SET_TOKEN", "");
          sessionStorage.removeItem('role');
          removeToken();
          resolve(1)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //获取当前用户的职位权限列表
    jobAuthorities({ commit }, p_id) {
      commit("SET_JOBAUTHOR", p_id);
    }
  }
}

export default user
