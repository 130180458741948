import Vue from 'vue'

Vue.directive('drawerDrag', {
    bind(el, binding, vnode, oldVnode) {
        const drawerEle = el.querySelector('.el-drawer')
        // 创建触发拖拽的元素
        const dragItem = document.createElement('div')
        // 将元素放置到抽屉的左边边缘
        dragItem.style.cssText = 'height: 5px;width: 100%;cursor: s-resize;position: absolute;top: 0;'
        // 添加鼠标移入事件，设置背景色为蓝色
        dragItem.addEventListener('mouseenter', function () {
            dragItem.style.backgroundColor = '#0090f1'
        })

        // 添加鼠标移出事件，恢复原来的背景色
        dragItem.addEventListener('mouseleave', function () {
            dragItem.style.backgroundColor = ''
        })
        drawerEle.append(dragItem)

        const moveCallback = function (e) {
            // e.preventDefault();
            // e.stopPropagation();
            // 获取鼠标距离浏览器上边缘的距离
            let realHeight = document.body.clientHeight - e.pageY
            const height30 = document.body.clientHeight * 0.1
            const height80 = document.body.clientHeight * 0.8
            // 高度不能小于 40%
            realHeight = realHeight > height80 ? height80 : realHeight < height30 ? height30 : realHeight
            drawerEle.style.height = realHeight + 'px'
        }

        dragItem.addEventListener('mousedown', () => {
            // 拖拽时禁用文本选中
            document.body.style.userSelect = 'none'
            document.addEventListener('mousemove', moveCallback)
            document.addEventListener('mouseup', () => {
                // 拖拽时结束时，取消禁用文本选中
                document.body.style.userSelect = 'initial'
                document.removeEventListener('mousemove', moveCallback)
                // document.removeEventListener('mouseup')
            })
        })
    }
})
