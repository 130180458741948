import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import notice from "./modules/notice";
import mindmap from "./modules/mindmap";
import project from "./modules/project";
import setting from "./modules/setting";
import release from "./modules/release";
import store_project from "./modules/store_project";
import store_tenant from "./modules/store_tenant";
import pingshen from "./modules/pingshen";
import board from "./modules/board";
import knowledge from "./modules/knowledge";
import getters from "./getters";
import group from './modules/set_group'
import theme from './modules/theme'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notice,
    mindmap,
    project,
    setting,
    release,
    board,
    knowledge,
    store_project,
    store_tenant,
    pingshen,
    group,
    theme
  },
  getters,

})
