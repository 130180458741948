const theme = {
    state: {
        theme: localStorage.getItem("_theme") ? localStorage.getItem("_theme") : "",
    },
    mutations: {
        SET_THEME(state, value) {
            state.theme = value;
            localStorage.setItem("_theme", value);
        }
    },
    actions: {
    }
}

export default theme
