import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 校验当前是否有重名版本
export function check_version_name(projectId, version_name) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versionNameVerification`,
    method: 'post',
    params: {
      versionName: version_name
    }
  })
}

// 创建版本
export function create_version(projectId, name, baselineIds, testPlanIds, releaseTarget) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions`,
    method: 'post',
    data: {
      name,
      baselineIds,
      testPlanIds,
      releaseTarget
    },
  })
}

export function get_all_versions(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions`,
    method: 'get'
  })
}

export function get_version_detail(projectId, versionId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}`,
    method: 'get'
  })
}
// 获取版本的节点信息
export function get_version_nodes(projectId, versionId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/nodes`,
    method: 'get'
  })
}

export function delete_version(projectId, versionId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}`,
    method: 'delete'
  })
}

export function edit_version(projectId, versionId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}`,
    data: data,
    method: 'put'
  })
}

// 新建节点-发布关联
export function add_nodes(projectId, versionId, nodeKeys) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/nodes`,
    data: nodeKeys,
    method: 'post'
  })
}

// 删除节点-发布关联
export function delete_nodes(projectId, versionId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/nodes/${nodeKey}`,
    method: 'delete'
  })
}

// 按类型获取sprints列表
export function get_sprints(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/scrumBoard/sprints`,
    method: 'get'
  })
}

// 添加关联基线
export function add_baselines(projectId, versionId, add_baseline_list) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/baselines`,
    data: add_baseline_list,
    method: 'post'
  })
}

// 删除关联基线
export function delete_baselines(projectId, versionId, baselineId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/baselines/${baselineId}`,
    method: 'delete'
  })
}

// 添加关联测试计划
export function add_testPlans(projectId, versionId, add_testPlan_list) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/testPlans`,
    data: add_testPlan_list,
    method: 'post'
  })
}

// 删除关联测试计划
export function delete_testPlans(projectId, versionId, testPlanId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/testPlans/${testPlanId}`,
    method: 'delete'
  })
}


// 添加关联sprint
export function add_sprints(projectId, versionId, add_sprint_list) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/sprints`,
    data: add_sprint_list,
    method: 'post'
  })
}

// 删除关联sprint
export function delete_sprints(projectId, versionId, sprintId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/sprints/${sprintId}`,
    method: 'delete'
  })
}

// 手动发布版本
export function release_version(projectId, versionId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/release`,
    method: 'post'
  })
}

//查看用户可选字段
export function get_version_select(projectId, versionId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/export/excel/fields`,
    method: 'get'
  })
}

//导出版本
export function export_excel_version(alllist) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${alllist.projectId}/versions/${alllist.versionId}/excel`,
    data: alllist.data,
    method: 'POST'
  })
}
export function updateVersionBatch(projectId, nodeKey, versionList) {

  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}/versions`,
    method: 'put',
    data: versionList,
  })
}

// 获取存档
export function updateArchive(projectId, versionId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/archive`,
    method: "post",
    data
  });
}
// 查看存档
export function get_Version_archive(projectId, versionId, pageNumber, pageSize) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/${versionId}/archiveList?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    method: "get",
  });
}
