import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取水印
export function getWatermark() {
    return request({
        url: `/${serviceConfig['account-service']}/tenant/watermark`,
        method: 'get'
    })
}
// 修改水印
export function putWatermark(data) {
    return request({
        url: `/${serviceConfig['account-service']}/tenant/watermark`,
        method: 'put',
        data,
    })
}