import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components';
import {
    GraphChart,
    LineChart,
    BarChart,
    PieChart,
    RadarChart,
    TreeChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use([
    TreeChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GraphChart,
    CanvasRenderer,
    GridComponent,
    DataZoomComponent,
    LineChart,
    BarChart,
    PieChart,
    RadarChart
]);

// 导出
export default echarts