import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)//注入到所有的子组件


// let langFileds = require.context('./config', false, /\.js$/)
//
// let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/ //正则用于匹配 ./en.js中的'en'
//
// let messages = {} //声明一个数据模型，对应i18n中的message属性
//
// langFileds.keys().forEach(key => {
//   let prop = regExp.exec(key)[1] //正则匹配en|zh这样的值
//   messages[prop] = langFileds(key).default
//
// })

import elementEnLocale from '../../node_modules/element-ui/lib/locale/lang/en'
import elementZhLocale from '../../node_modules/element-ui/lib/locale/lang/zh-CN'
import eleLocale from '../../node_modules/element-ui/lib/locale'
import enLocale from './config/en.js' // 本地英文包
import zhLocale from './config/zh.js' // 本地中文包

let messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
}

let locale = localStorage.getItem('lang') || "zh" //从localstorage中获取

let i18n = new VueI18n ({
  locale, //指定语言字段
  messages, //定义语言字段
  silentTranslationWarn: true,
})

eleLocale.i18n((key, value) => i18n.t(key, value)) // 在注册Element时设置i18n的处理方法

export default i18n
