const setting = {
  state: {
    current_spirnt: sessionStorage.getItem("current_spirnt") || "{}"
  },
  mutations: {
    SET_CURRENT_SPIRNT: (state, current_spirnt) => {
      sessionStorage.setItem("current_spirnt", current_spirnt);
      state.current_spirnt = current_spirnt;
    }
  }
}

export default setting
