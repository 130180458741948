const knowledge = {
    state: {
        knowledge_edit: false,
        knowledge_edit_mode: '',
    },
    mutations: {
        SET_KNOWLEDGE_EDIT: (state, {knowledge_edit, knowledge_edit_mode}) => {
            state.knowledge_edit = knowledge_edit;
            state.knowledge_edit_mode = knowledge_edit_mode;
        }
    }
}
export default knowledge