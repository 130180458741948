const pingshen = {
  state: {
    pingshenNumberIsToUpdate: false
  },

  mutations: {
    updatePingshenNumber(state, val) {
      state.pingshenNumberIsToUpdate = val;
    }
  },

}

export default pingshen
