const mindmap = {
  state: {
    mindmaps: [],
    templates: [],
    exist_mindmap: {},
    permission: '', //四种可能：owner, edit, view, review
    mindmap_name: '',
    file_id: '',
    current_time: '',
    open_state: '',
    type: '',
    sheet: '',
    typed: '',
    mindmap_owner: '', // 原文件所有者 email
    loading_review: false,
    review_res_flag: false,
    file_type_id: '',
    file_key: '',
    cancel_auto: false, // 关联透视图右键单击直接取消，不询问
    is_copy: false, // drawio
  },

  mutations: {
    SET_MINDMAPS: (state, mindmaps) => {
      state.mindmaps = mindmaps
    },
    SET_FILE_ID: (state, file_id) => {
      state.file_id = file_id
    },
    SET_TEMPLATES: (state, templates) => {
      state.templates = templates
    },
    SET_EXIST_MINDMAP: (state, exist_mindmap) => {
      const map = {};
      exist_mindmap.data ? exist_mindmap.data.forEach(item => {
        if (map[item.layer]) {
          map[item.layer].push(item)
        } else {
          map[item.layer] = [item];
        }
      }) : '';
      const arr = [];
      for (let key in map) {
        map[key].sort((a, b) => {
          return a.index > b.index ? 1 : -1;
        })
        arr.push(...map[key]);
      }
      exist_mindmap.data = arr;
      state.exist_mindmap = exist_mindmap
    },
    SET_MAP_PERMISSION: (state, permission) => {
      state.permission = permission
    },
    SET_MINDMAP_NAME: (state, name) => {
      state.mindmap_name = name
    },
    SET_CURRENT_TIME: (state, current) => {
      state.current_time = current
    },
    SET_OPEN_STATE: (state, open_state) => {
      state.open_state = open_state
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    SET_SHEET: (state, sheet) => {
      state.sheet = sheet
    },
    SET_TYPED: (state, typed) => {
      state.typed = typed
    },
    SET_MINDMAP_OWNER: (state, mindmap_owner) => {
      state.mindmap_owner = mindmap_owner
    },
    SET_LR: (state, loading_review) => { // SET_LR 暂时没有用
      state.loading_review = loading_review
    },
    SET_RR: (state, review_res_flag) => {
      state.review_res_flag = review_res_flag
    },
    SET_FILETYPE_ID: (state, file_type_id) => {
      state.file_type_id = file_type_id
    },
    SET_FILE_KEY: (state, file_key) => {
      state.file_key = file_key
    },
    SET_CANCEL_AUTO: (state, cancel_auto) => {
      state.cancel_auto = cancel_auto
    },
    SET_IS_COPY: (state, is_copy) => {
      state.is_copy = is_copy
    },
  },

  actions: {

  }
}

export default mindmap