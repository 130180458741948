import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取项目组中的状态列表
export function get_status_list(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/statuses`,
    method: "get",
  });
}

// 新增状态列表
export function add_status_list(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/statuses`,
    method: "post",
    data,
  });
}
// 获取项目组中的已经被使用的状态列表
export function get_allreadyuse_status_list(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/statuses/usage`,
    method: "get",
  });
}

// 获取指定文件层级的工作流详情
export function get_workflow_resource(projectId, fileTypeId, layerNumber) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypeLayers/${fileTypeId}/layers/${layerNumber}/workflow`,
    method: "get",
  });
}

// 更新工作流
export function set_workflow_resource({
  projectId,
  fileTypeId,
  layerNumber,
  data,
}) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypeLayers/${fileTypeId}/layers/${layerNumber}/workflow`,
    method: "put",
    data,
  });
}

// 获取项目组中的工作流模板表
export function get_workflow_template(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/workflowTemplates`,
    method: "get",
  });
}

// 创建一个工作流模板
export function create_workflow_template(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/workflowTemplates`,
    method: "post",
    data,
  });
}

// 重命名一个工作流模板
export function rename_workflow_template(projectId, workflowTemplateId, newName) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/workflowTemplates/${workflowTemplateId}/rename?name=${newName}`,
    method: "put",
  });
}

// 删除一个工作流模板
export function delete_workflow_template(projectId, workflowTemplateId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/workflowTemplates/${workflowTemplateId}`,
    method: "delete",
  });
}

// 预览一个工作流模板
export function show_workflow_template(projectId, workflowTemplateId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/workflowTemplates/${workflowTemplateId}`,
    method: "get",
  });
}

// 切换被删除的状态
export function set_matching_status({
  projectId,
  fileTypeId,
  layerNumber,
  data,
}) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypeLayers/${fileTypeId}/layers/${layerNumber}/deletedStatusModify`,
    method: "put",
    data,
  });
}
//获取工作流触发器类型列表
export function get_workflow_typeList() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/workflow/trigerTypes`,
    method: "get",
  });
}

// 获取工作流触发器列表
export function get_workflow_list(projectId, workflowId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflows/${workflowId}/triggers`,
    method: "get",
  });
}

// 添加工作流触发器
export function add_workflow_trigger(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/triggers`,
    method: "POST",
    data
  });
}

// 删除工作流触发器
export function delete_workflow_trigger(projectId, triggerId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/triggers/${triggerId}`,
    method: "DELETE",
  });
}

//获取工作流验证条件类型列表
export function get_conditions_typeList() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/workflow/validatorTypes`,
    method: "get",
  });
}

// 获取工作流验证条件列表
export function get_conditions_list(projectId, workflowId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflows/${workflowId}/validators`,
    method: "get",
  });
}

// 添加工作流验证条件
export function add_workflow_conditions(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/validators`,
    method: "POST",
    data
  });
}

// 删除工作流验证条件
export function delete_workflow_conditions(projectId, validatorId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/validators/${validatorId}`,
    method: "DELETE",
  });
}
// 获取工作流后处理类型列表
export function get_processing_typeList() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/workflow/postHandlerTypes`,
    method: "get",
  });
}

// 获取工作流后处理列表
export function get_processing_list(projectId, workflowId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflows/${workflowId}/postHandlers`,
    method: "get",
  });
}

// 添加工作流后处理
export function add_workflow_processing(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/postHandlers`,
    method: "POST",
    data
  });
}

// 删除工作流验证条件
export function delete_workflow_processing(projectId, postHandlerId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/postHandlers/${postHandlerId}`,
    method: "DELETE",
  });
}

// 获取校验器的判断逻辑
export function get_workflow_conditions_judgeLogic(projectId, params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/validator/judgeLogic?fromStatusId=${params.fromStatusId}&toStatusId=${params.toStatusId}&workflowId=${params.workflowId}`,
    method: "get",
  });
}

// 修改校验器的判断逻辑
export function change_workflow_conditions_judgeLogic(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/validator/judgeLogic`,
    method: "put",
    data
  });
}

// 查询是否需要付费提醒弹窗
export function workflow_payFeatureValid(projectId, workflowId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflows/${workflowId}/payFeatureValid`,
    method: "get",
  });
}






