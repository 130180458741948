import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取用户所有通知
export function get_notifications(email) {
  const data = {
    email: email
  }
  return request({
    url: `/notification`,
    method: 'post',
    data: data
  })
}

// 标记通知为已读
export function mark_notifications_read(email, notices) {
  const data = {
    email: email,
    notices: notices
  }
  return request({
    url: `/notification`,
    method: 'post',
    data: data
  })
}
// 获取站内通知数据
export function get_notifications_list(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/notifications`,
    method: 'get',
    params: params
  })
}
// 获取通知数量
export function unSeen() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/notifications/unSeen`,
    method: 'post',
  })
}
// 获取通知数量
export function seenSingle(notificationId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/notifications/${notificationId}`,
    method: 'post',
  })
}
// 删除已读
export function deleteSeen(notificationId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/notifications/seen`,
    method: 'delete',
  })
}
// 全部标记为已读
export function allSeen(notificationId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/notifications/allSeen`,
    method: 'post',
  })
}
// 获取站内通知类型
export function get_type_list() {
  return request({
    url: `${serviceConfig['file-manage-service']}/notificationsTypeList`,
    method: 'get',
  })
}
// 获取周报通知功能
export function getWeeklyReportNotifySwitch() {
  return request({
    url: `${serviceConfig['file-manage-service']}/weeklyReportNotifySwitch`,
    method: 'get',
  })
}
// 修改周报通知功能
export function setWeeklyReportNotifySwitch() {
  return request({
    url: `${serviceConfig['file-manage-service']}/weeklyReportNotifySwitch`,
    method: 'put',
  })
}