import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from "@/utils/common";
import router from '@/router'
import i18n from '@/i18n/index'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 180000
})

// request拦截器
service.interceptors.request.use(config => {
  if (router.currentRoute.params && router.currentRoute.params.projectId) {
    config.headers['Projectid'] = router.currentRoute.params.projectId
  }
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  const lang = localStorage.getItem("lang");

  if (lang === "en") {
    config.headers['Accept-Language'] = 'en-US'
  } else if (lang === "zh") {
    config.headers['Accept-Language'] = 'zh-CN'
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  const successful_code = /^2\d{2}$/;
  // 未设置状态码则默认成功状态
  const code = res.data ? res.data.code : null || res.status;
  // 获取错误信息
  const msg = res.data ? res.data.msg : null || res.data ? res.data.message : null || errorCode[code] || errorCode['default'];

  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }

  return res.data
},
  error => {
    const code = error.response.status
    const msg = errorCode[code] || error.response.data.message
    const client_error_code = /^4\d{2}$/;
    const server_error_code = /^5\d{2}$/;

    // 根据具体 http 状态码(4xx)做进一步处理
    if (client_error_code.test(code)) {
      if (code === 401) {
        if (error.response.data && error.response.data.length) {
          Message.closeAll();
          Message({
            message: i18n.t('Loginfailed'),
            type: 'error',
            duration: 5 * 1000
          })
        }
        removeToken();
        router.replace("/login/");
      } else if (code === 429) {
        Message.closeAll();
        Message({
          message: '请求频率过高，请稍后再试',
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        Message.closeAll();
        Message({
          message: msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(error)
    }

    // 根据具体 http 状态码(5xx)做进一步处理
    if (server_error_code.test(code)) {
      Message.closeAll();
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service