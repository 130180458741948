<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["watermask_switch", "watermask_value", "user_info"]),
  },
  watch: {
    watermask_switch: {
      immediate: true,
      handler(value) {
        if (value) {
          this.init();
        } else {
          this.remove();
        }
      },
    },
    watermask_value(value) {
      if (this.watermask_switch) {
        window.location.reload();
      }
    },
  },
  mounted() {
    this.Monitor();
  },
  beforeDestroy() {
    this.remove();
  },
  methods: {
    callback(event) {
      if (
        event[0] &&
        event[0].removedNodes.length &&
        event[0].removedNodes[0].id === "_waterMark" &&
        this.watermask_switch === true
      ) {
        this.init();
      } else if (
        event[0] &&
        event[0].target &&
        event[0].target.id === "_waterMark" &&
        event[0].type === "attributes" &&
        this.watermask_switch === true
      ) {
        this.init();
      }
    },
    Monitor() {
      let body = document.getElementsByTagName("body")[0];
      let options = {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true,
      };
      let observer = new MutationObserver(this.callback);
      observer.observe(body, options); // 监听body节点
    },
    remove() {
      const domList = document.body.querySelectorAll("#_waterMark");
      if (domList.length) {
        domList.forEach((node) => {
          document.body.removeChild(node);
        });
      }
    },
    init() {
      let canvas = document.createElement("canvas");
      canvas.id = "canvas";
      canvas.width = "200"; // 单个水印的宽高
      canvas.height = "130";
      this.maskDiv = document.createElement("div");
      let ctx = canvas.getContext("2d");
      ctx.font = "normal 18px Microsoft Yahei"; // 设置样式
      ctx.fillStyle = "rgba(112, 113, 114, 0.1)"; // 水印字体颜色
      ctx.rotate((30 * Math.PI) / 180); // 水印偏转角度
      ctx.fillText(
        this.watermask_value + this.user_info.email.split("@")[0],
        30,
        20
      );
      let src = canvas.toDataURL("image/png");
      this.maskDiv.style.position = "fixed";
      this.maskDiv.style.zIndex = "9999";
      this.maskDiv.id = "_waterMark";
      this.maskDiv.style.top = "30px";
      this.maskDiv.style.left = "0";
      this.maskDiv.style.height = "100%";
      this.maskDiv.style.width = "100%";
      this.maskDiv.style.pointerEvents = "none";
      this.maskDiv.style.backgroundImage = "URL(" + src + ")";
      // 水印节点插到body下
      document.body.appendChild(this.maskDiv);
    },
  },
};
</script>

<style lang="scss" scoped></style>
